import { BreakpointName } from './types';

type BreakpointsInPx = Record<BreakpointName, string>;

export type MaxWidthBreakpointsInPx = Omit<
  BreakpointsInPx,
  BreakpointName.BigDesktop
>;

interface Output {
  minWidth: BreakpointsInPx;
  maxWidth: MaxWidthBreakpointsInPx;
}

export const convertBreakpointsToAppThemeUse = (
  breakpoints: Record<BreakpointName, number>,
): Output => {
  const OBJECT_ENTRY_VALUE_INDEX = 1;
  return Object.entries(breakpoints).reduce<any>(
    (acc, [curKey, curVal], i, arr) => {
      const breakpointName = curKey;
      const startsAt = curVal;

      const nextEntry = arr[i + 1];
      const nextEntryValue =
        nextEntry === undefined
          ? undefined
          : nextEntry[OBJECT_ENTRY_VALUE_INDEX];
      const endsAt =
        nextEntryValue === undefined ? Infinity : nextEntryValue - 1;

      return {
        minWidth: Object.assign({}, acc.minWidth, {
          [breakpointName]: `${startsAt}px`,
        }),
        maxWidth: Object.assign(
          {},
          acc.maxWidth,
          endsAt === Infinity ? undefined : { [breakpointName]: `${endsAt}px` },
        ),
      };
    },
    {
      minWidth: {},
      maxWidth: {},
    },
  );
};
