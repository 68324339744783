import React from 'react';

const ArrowRightSvg = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0024 0.743987C9.68389 1.06949 9.68389 1.59538 10.0046 1.91866L13.645 5.59768L13.729 5.67199C14.0505 5.91963 14.5093 5.89384 14.8013 5.59546C14.9606 5.43271 15.0403 5.22125 15.0403 5.00979C15.0403 4.79611 14.9606 4.58354 14.7992 4.42079L11.1599 0.741773L11.0758 0.667709C10.7542 0.420918 10.2944 0.446628 10.0024 0.743987ZM0.715597 7.17595C0.312323 7.22722 0 7.57674 0 7.99983C0 8.45819 0.366545 8.83019 0.818182 8.83019L15.2007 8.83019L10.0047 14.0813L9.93123 14.1661C9.68621 14.4909 9.71055 14.9576 10.0025 15.256C10.3211 15.5804 10.8393 15.5815 11.1589 15.2582L17.7589 8.58772L17.8317 8.5042C17.9424 8.35812 18 8.17974 18 7.99983C18 7.89244 17.9793 7.78505 17.9378 7.68319C17.8113 7.37209 17.5135 7.16948 17.1818 7.16948L0.818182 7.16948L0.715597 7.17595Z"
        fill="#0A2857"
      />
    </svg>
  );
};

export default ArrowRightSvg;
