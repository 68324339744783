import React, { FC, ComponentProps, ReactNode } from 'react';
import clsx from 'clsx';
import omit from 'lodash/omit';

import classes from './index.module.scss';

type TextAlignValues = 'left' | 'right' | 'center';

export const H1Hero: FC<ComponentProps<'h1'>> = (props) => {
  return (
    <h1 {...props} className={clsx(classes.h1Hero, props.className)}>
      {props.children}
    </h1>
  );
};

type PProps = ComponentProps<'p'> & {
  bold?: boolean;
  underline?: boolean;
  center?: boolean;
  italic?: boolean;
  disableMargin?: boolean;
};

export const P = (props: PProps) => {
  const { italic, bold, underline, center, disableMargin } = props;
  return (
    <p
      {...omit(props, [
        'italic',
        'bold',
        'underline',
        'center',
        'disableMargin',
      ])}
      className={clsx(classes.p, props.className, {
        [classes.p_italic]: italic,
        [classes.p_bold]: bold,
        [classes.p_underline]: underline,
        [classes.p_center]: center,
        [classes.p_disableMargin]: disableMargin,
      })}
    >
      {props.children}
    </p>
  );
};

export const H1: FC<
  ComponentProps<'h1'> & {
    withKv?: boolean;
    textAlign?: TextAlignValues;
    disableMargin?: boolean;
    children?: ReactNode;
  }
> = (props) => {
  return (
    <h1
      {...omit(props, ['withKv', 'disableMargin', 'textAlign'])}
      className={clsx(
        classes.h1,
        {
          [classes.h1_withKv]: props.withKv,
          [classes.h1_disableMargin]: props.disableMargin,
        },
        classes[`textAlign_${props.textAlign}`],
        props.className,
      )}
    >
      {props.children}
    </h1>
  );
};

type H2Props = ComponentProps<'h2'> & {
  textAlign?: TextAlignValues;
  disableMargin?: boolean;
};

export const H2 = (props: H2Props) => {
  return (
    <h2
      {...omit(props, ['textAlign', 'disableMargin'])}
      className={clsx(
        classes.h2,
        {
          [classes.h2_disableMargin]: props.disableMargin,
          [classes[`textAlign_${props.textAlign}`]]: props.textAlign,
        },
        props.className,
      )}
    >
      {props.children}
    </h2>
  );
};

type H3Props = ComponentProps<'h3'> & {
  disableMarginTop?: boolean;
  disableMargin?: boolean;
};

export const H3 = (props: H3Props) => {
  return (
    <h3
      {...omit(props, ['disableMarginTop', 'disableMargin'])}
      className={clsx(
        classes.h3,
        {
          [classes.h3_disableMargin]: props.disableMargin,
          [classes.h3_disableMarginTop]: props.disableMarginTop,
        },
        props.className,
      )}
    >
      {props.children}
    </h3>
  );
};

export const I: FC<{ underline?: boolean; children: ReactNode }> = ({
  underline,
  children,
}) => {
  return <i className={clsx(underline && classes.i_underline)}>{children}</i>;
};

export const TextDecoration: FC<{
  underline?: boolean;
  bold?: boolean;
  children?: ReactNode;
}> = ({ bold, children, underline }) => {
  return (
    <span
      className={clsx({
        [classes.textDecoration_underline]: underline,
        [classes.textDecoration_bold]: bold,
      })}
    >
      {children}
    </span>
  );
};
