import { RectanglesPositions } from '../../types';

export const defaultPositions: RectanglesPositions = {
  desktop: [
    {
      x: -6,
      y: 61,
      height: 21,
      width: 257,
    },
    {
      x: 398,
      y: 111,
      height: 21,
      width: 362,
    },
    {
      x: 255,
      y: 203,
      height: 21,
      width: 286,
    },
    {
      x: -6,
      y: 280,
      height: 21,
      width: 270,
    },
    {
      x: 557,
      y: 414,
      height: 21,
      width: 286,
    },
  ],
  mobile: [
    {
      x: 28,
      y: 139,
      height: 21,
      width: 257,
    },
    {
      x: 230,
      y: 295,
      height: 21,
      width: 362,
    },
    {
      x: 100,
      y: 400,
      height: 21,
      width: 362,
    },
    {
      x: -120,
      y: 505,
      height: 21,
      width: 270,
    },
  ],
};
