import { BreakpointName, MaxWidthBreakpointName } from './types';

export type Breakpoints = Record<BreakpointName, number>;

export const BREAKPOINTS: Breakpoints = {
  [BreakpointName.Zero]: 0,
  [BreakpointName.Sm]: 360,
  [BreakpointName.Md]: 576,
  [BreakpointName.Lg]: 768,
  [BreakpointName.Xl]: 992,
  [BreakpointName.Desktop]: 1170,
  [BreakpointName.BigDesktop]: 1600,
};

export const defaultDesktopFirstBreakpointName = BreakpointName.BigDesktop;

export const breakpointsNamesDesktopFirst: MaxWidthBreakpointName[] =
  ((): MaxWidthBreakpointName[] => {
    const sortedBreakpointNames = Object.entries(BREAKPOINTS)
      .sort(([, breakpointAStartsAt], [, breakpointBStartsAt]) => {
        return breakpointBStartsAt - breakpointAStartsAt;
      })
      .map<BreakpointName>(([key]) => key as BreakpointName);

    const sortedBreakpointsNamesWithoutLargestOne =
      sortedBreakpointNames.shift();
    if (!sortedBreakpointsNamesWithoutLargestOne) return [];

    return sortedBreakpointNames as MaxWidthBreakpointName[];
  })();
