import React from 'react';
import clsx from 'clsx';
import { animated, useSpring } from 'react-spring';
import { useDrag } from '@use-gesture/react';

import classes from './index.module.scss';

import SlideButton from '@/components/common/Button/CarouselButton';
import { BreakpointName } from '@/styles/constants/breakpoints/types';
import { defaultDesktopFirstBreakpointName } from '@/styles/constants/breakpoints';
import ArrowLeftSvg from '@/components/Carousel/ArrowLeft';
import ArrowRightSvg from '@/components/Carousel/ArrowRight';
import { pushToDataLayer } from '@/helpers/googleTagManager';

type ButtonsSettingsLayout = 'sides' | 'top' | 'bottom' | 'none';
type ButtonsSettings = Partial<Record<BreakpointName, ButtonsSettingsLayout>>;

const DEFAULT_BUTTONS_SETTINGS: ButtonsSettings = {
  [BreakpointName.Zero]: 'bottom',
  [BreakpointName.Desktop]: 'sides',
};

interface Props {
  currentSlideIndex: number;
  onSlideChange: (slideIndex: number) => void;
  pages: React.ReactNode[];
  className?: string;
  hideButtons?: boolean;
  hideSlideIndicators?: boolean;
  buttonsSettings?: ButtonsSettings;
  gtm?: string;
}

const NO_DRAG_OFFSET = 0;
const DRAG_DISTANCE_CONSIDERED_LONG_ENOUGH_TO_CHANGE_SLIDE = 200;

const Carousel: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  currentSlideIndex,
  onSlideChange,
  pages,
  hideButtons = false,
  hideSlideIndicators = false,
  className,
  buttonsSettings = DEFAULT_BUTTONS_SETTINGS,
  gtm,
}) => {
  const [slideCssSpring, slideCssSpringApi] = useSpring(
    () => ({
      to: {
        transform: `translate3d(calc(-100% * ${currentSlideIndex} + ${NO_DRAG_OFFSET}px), 0, 0)`,
      },
    }),
    [currentSlideIndex],
  );

  const drag = useDrag(
    (gestureState) => {
      const [movementX] = gestureState.movement;

      if (gestureState.active) {
        slideCssSpringApi.set({
          transform: `translate3d(calc(-100% * ${currentSlideIndex} + ${movementX}px), 0, 0)`,
        });
      } else {
        slideCssSpringApi.start({
          to: {
            transform: `translate3d(calc(-100% * ${currentSlideIndex} + ${NO_DRAG_OFFSET}px), 0, 0)`,
          },
        });
      }

      const [swipeX] = gestureState.swipe;
      const hasSwipedLeft = swipeX < 0;
      const hasSwipedRight = swipeX > 0;
      const hasDraggedLeft =
        movementX < -DRAG_DISTANCE_CONSIDERED_LONG_ENOUGH_TO_CHANGE_SLIDE &&
        !gestureState.down;
      const hasDraggedRight =
        movementX > DRAG_DISTANCE_CONSIDERED_LONG_ENOUGH_TO_CHANGE_SLIDE &&
        !gestureState.down;

      if (hasSwipedLeft || hasDraggedLeft) {
        if (gtm) {
          pushToDataLayer('custom_click', {
            clickedElement: `swipe-left-${gtm}`,
          });
        }

        setNextPage();
      }

      if (hasSwipedRight || hasDraggedRight) {
        if (gtm) {
          pushToDataLayer('custom_click', {
            clickedElement: `swipe-right-${gtm}`,
          });
        }

        setPrevPage();
      }
    },
    {
      preventScroll: true,
    },
  );

  const setNextPage = () => {
    let nextSlideIndex = currentSlideIndex + 1;
    if (nextSlideIndex >= pages.length) nextSlideIndex = 0;

    onSlideChange(nextSlideIndex);
  };

  const setPrevPage = () => {
    let previousSlideIndex = currentSlideIndex - 1;
    if (previousSlideIndex < 0) previousSlideIndex = pages.length - 1;

    onSlideChange(previousSlideIndex);
  };

  const buttonSettingsLayout =
    buttonsSettings[defaultDesktopFirstBreakpointName] ?? 'sides';

  return (
    <div className={clsx(classes.layout, className)}>
      {!hideButtons && (
        <div>
          <SlideButton
            className={clsx(
              classes.button,
              buttonSettingsLayout &&
                classes[`prevButton_${buttonSettingsLayout}`],
              ...Object.entries(buttonsSettings).map(
                ([bpName, layout]) => classes[`prevButton_${layout}_${bpName}`],
              ),
            )}
            onClick={() => {
              if (gtm) {
                pushToDataLayer('custom_click', {
                  clickedElement: `prev-arrow-${gtm}`,
                });
              }

              setPrevPage();
            }}
          >
            <ArrowLeftSvg />
          </SlideButton>
          <SlideButton
            className={clsx(
              classes.button,
              buttonSettingsLayout &&
                classes[`nextButton_${buttonSettingsLayout}`],
              ...Object.entries(buttonsSettings).map(
                ([bpName, layout]) => classes[`nextButton_${layout}_${bpName}`],
              ),
            )}
            onClick={() => {
              if (gtm) {
                pushToDataLayer('custom_click', {
                  clickedElement: `next-arrow-${gtm}`,
                });
              }

              setNextPage();
            }}
          >
            <ArrowRightSvg />
          </SlideButton>
        </div>
      )}

      {!hideSlideIndicators && (
        <div className={classes.slideIndicators}>
          {pages.map((_page, index) => (
            <div
              className={clsx(
                classes.slideIndicator,
                index === currentSlideIndex && classes.slideIndicator_active,
              )}
              key={index}
              onClick={() => {
                onSlideChange(index);
              }}
            />
          ))}
        </div>
      )}

      <div className={classes.slidesContainer} {...drag()}>
        <animated.div className={classes.slides} style={slideCssSpring}>
          {pages.map((page, index) => (
            <div className={classes.slideContainer} key={index}>
              <div className={classes.slide}>{page}</div>
            </div>
          ))}
        </animated.div>
      </div>
    </div>
  );
};

export default Carousel;
