import React from 'react';

const ArrowLeftSvg = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99756 15.256C8.31611 14.9305 8.31611 14.4046 7.99538 14.0813L4.35502 10.4023L4.27097 10.328C3.94949 10.0804 3.49066 10.1062 3.19866 10.4045C3.03938 10.5673 2.95975 10.7787 2.95975 10.9902C2.95975 11.2039 3.03938 11.4165 3.20084 11.5792L6.84011 15.2582L6.92417 15.3323C7.24581 15.5791 7.70556 15.5534 7.99756 15.256ZM17.2844 8.82405C17.6877 8.77278 18 8.42326 18 8.00017C18 7.54181 17.6335 7.16981 17.1818 7.16981L2.79927 7.16981L7.99527 1.91866L8.06878 1.83385C8.31379 1.50912 8.28946 1.04236 7.99746 0.743985C7.67891 0.419594 7.16073 0.418488 6.84109 0.741772L0.241091 7.41227L0.168309 7.4958C0.0575759 7.64188 6.71385e-07 7.82025 6.55656e-07 8.00016C6.46268e-07 8.10756 0.0207278 8.21495 0.0621821 8.31681C0.188727 8.62791 0.486546 8.83052 0.818183 8.83052L17.1818 8.83052L17.2844 8.82405Z"
        fill="#0A2857"
      />
    </svg>
  );
};

export default ArrowLeftSvg;
