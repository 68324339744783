import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import classes from './index.module.scss';

import { gapInPx } from '@/styles/theme';
import { MaxWidthBreakpointsInPx } from '@/styles/constants/breakpoints/helpers';
import { BreakpointName } from '@/styles/constants/breakpoints/types';

export type LabelSize = 'normal' | 'small';

const labelFormatter = (text: string) => {
  return text.replaceAll('  ', '&emsp;').replaceAll(' ', '&nbsp;');
};

export interface OffsetSettings {
  value: string;
  breakPoints?: {
    name: keyof MaxWidthBreakpointsInPx;
    value: string;
  }[];
}

const SectionLabel: React.FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
    labelText: string;
    offset?: OffsetSettings;
    size?: LabelSize;
  }>
> = ({
  className,
  labelText,
  offset = {
    value: gapInPx(10),
    breakPoints: [{ name: BreakpointName.Sm, value: gapInPx(2) }],
  },
  size = 'normal',
}) => {
  return (
    <div
      className={clsx(
        classes.labelWrapper,
        offset.breakPoints?.map(
          (bp) => classes[`labelWrapper_offset_${bp.name}`],
        ),
        className,
      )}
      style={
        {
          '--offsetValue': offset.value,
          ...offset.breakPoints?.reduce((acc, bp) => {
            return {
              ...acc,
              [`--offsetValue${bp.name}`]: bp.value,
            };
          }, {}),
        } as CSSProperties
      }
    >
      <p
        className={clsx(classes.labelText, {
          [classes.labelText_small]: size === 'small',
        })}
      >
        <span
          className={classes.labelTextContent}
          dangerouslySetInnerHTML={{ __html: labelFormatter(labelText) }}
        />
      </p>
    </div>
  );
};

export default SectionLabel;
