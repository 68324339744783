import { BREAKPOINTS } from '../constants/breakpoints';
import { convertBreakpointsToAppThemeUse } from '../constants/breakpoints/helpers';

const GAP_MULTIPLIER = 8;
export type GapMultiplier =
  | 0.4
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 16
  | 20
  | 22
  | 30;
export const gapInPx = (multiplier: GapMultiplier): string =>
  `${multiplier * GAP_MULTIPLIER}px`;

const containerWidths = {
  xl: '117rem',
  lg: '99.2rem',
  md: '76.8rem',
  sm: '57.6rem',
};

const containerPaddings = {
  x: gapInPx(2),
  y: gapInPx(9),
  smX: gapInPx(2),
  smY: gapInPx(5),
};

/* deprecated? use gapInPx(multiplier: GapMultiplier) => string */
const gaps = {
  xxs: gapInPx(0.5),
  xs: gapInPx(1),
  s: gapInPx(2),
  m: gapInPx(3),
  l: gapInPx(4),
  xl: gapInPx(5),
  xxl: gapInPx(8),
};

export const theme_OLD = {
  breakPoints: convertBreakpointsToAppThemeUse(BREAKPOINTS),
  container: {
    width: containerWidths,
    padding: {
      ...containerPaddings,
      xy: `${containerPaddings.y} ${containerPaddings.x}`,
      smXY: `${containerPaddings.smY} ${containerPaddings.smX}`,
    },
  },
  colors: {
    primaryColor1: '#002957',
    primaryColor2: '#008ad0',
    secondaryColor1: '#8cc6ec',
    secondaryColor2: '#5eaee1',
    typographyColor: '#062340',
    grayBlue: '#f2f9fd',
    grayDark: '#c0c7da',
    gray: '#dde2ef',
    grayLight: '#f4f5f9',
    grayLighter: '#F4FAFE',
    white: '#fff',
    successColor: '#11c080',
    errorColor: '#f76767',
    linkMobilityPrimaryColor1: '#00bbe4',
    linkMobilityPrimaryColor2: '#e9701d',
    linkMobilityGreyColor: '#393939',
  },
  font: {
    weight: {
      normal: 400,
      medium: 500,
      bold: 700,
    },
    size: {
      base: '1.8rem',
      small: '1.6rem',
      tiny: '1.2rem',
      h1: '3.8rem',
      h1Sm: '2.6rem',
      h2: '2.4rem',
      h2Sm: '2rem',
      h3: '2rem',
      h3Sm: '1.8rem',
      h4: '1.6rem',
      h1Hero: '5.6rem',
      h1HeroSm: '3.8rem',
      pHero: '2.4rem',
      pHeroSm: '2.4rem',
    },
    family: '"DINWebPro", "Arial", sans-serif',
  },
  gaps,
  lineHeight: {
    button: '1.3',
    header: '1.4',
    hero: '1.2',
    paragraph: '1.6',
  },
  border: {
    radius: '0.4rem',
    width: '0.2rem',
    smooth: '0.2rem rgba(255, 255, 255, 0.1) solid',
  },
  button: {
    hoverDarkenLevel: '10%',
    activeDarkenLevel: '10%',
    borderDarkenLevel: '5%',
    hoverTransitionTime: '0.2s',
  },
  input: {
    padding: {
      x: '16px',
      y: '8px',
    },
  },
  shadow: 'rgba(0, 0, 0, 0.15) 0px 4px 8px',
} as const;

export type Theme = typeof theme_OLD;

const breakPoints = convertBreakpointsToAppThemeUse(BREAKPOINTS);

const colors = {
  weldonBlue: '#8291A9',
  darkBlue: '#0A2857',
  darkBlueGray: '#616e89',
  skyBlue: '#8cceec',
  white: '#fff',
  blue: '#2E8AD0',
  lightDarkBlue: '#164194',
  heavyDarkBlue: '#041938',
  green: '#39C080',
  grey: '#606879',
  coolGrey: '#8891A5',
  lightGrey: '#E5EEF7',
  lighterGrey: '#F4FAFE',
  lighterBlue: '#77BDEB',
  lightBlue: '#2E8AD0',
  azureishWhite: '#dbe5ef',
  iceberg: '#d9e4ee',
  cultured: '#f8f8f8',
  grayBlue: '#eef4f8',
  alto: '#DFDFDF',
  dirtyWhite: '#D9D9D9',
  water: '#D8EBFF',
  // Old page colors
  red: '#f76767',
  grayDark: '#c0c7da',
  gray: '#dde2ef',
  secondaryColor2: '#5eaee1',
} as const;

const font = {
  size: {
    medium: '1.8rem',
    base: '1.6rem',
    small: '1.3rem',
    tiny: '1rem',
    xs: '0.8rem',
    desktop: {
      h1: '3.8rem',
      h2: '2.8rem',
      h3: '2rem',
      h4: '1.6rem',
      h1Hero: '4.8rem',
      pHero: '2rem',
    },
    mobile: {
      h1: '2.4rem',
      h2: '2rem',
      h3: '1.8rem',
      h4: '1.6rem',
      h1Hero: '3.2rem',
      pHero: '1.6rem',
    },
  },
  weight: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  family: '"DINWebPro", "Arial", sans-serif',
} as const;

const lineHeight = {
  header: '1.4',
  hero: '1.2',
  paragraph: '1.6',
} as const;

const border = {
  width: { xs: '0.1rem', s: '0.2rem', m: '0.3rem', l: '0.4rem' },
  smooth: '0.2rem rgba(255, 255, 255, 0.1) solid',
  radius: {
    s: '0.2rem',
    m: '0.3rem',
    l: '0.4rem',
    xl: '0.5rem',
  },
} as const;

const container = {
  width: containerWidths,
  padding: {
    ...containerPaddings,
    xy: `${containerPaddings.y} ${containerPaddings.x}`,
    smXY: `${containerPaddings.smY} ${containerPaddings.smX}`,
  },
} as const;

export const theme = {
  colors,
  font,
  breakPoints,
  border,
  lineHeight,
  container,
};
