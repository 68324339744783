import { ImageProps } from 'next/image';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Image from 'next/image';

import classes from './index.module.scss';
import BackgroundRectangles from './components/BackgroundRectangles';
import { RectanglesPositions } from './types';

import SectionLabel, {
  LabelSize,
  OffsetSettings,
} from '@/components/common/SectionLabel';
import Heading from '@/components/common/Header';

const DEFAULT_LAYOUT_SETTINGS: LayoutSettings = {
  textGridTemplateColumn: '5fr',
  imageGridTemplateColumn: '3fr',
  textGridTemplateRow: '5fr',
  imageGridTemplateRow: '3fr',
  twoColoredBackground: false,
  twoColoredBackgroundOffset: 0,
  withBackgroundRectangles: false,
};

interface LayoutSettings {
  imageGridTemplateColumn: string;
  textGridTemplateColumn: string;
  imageGridTemplateRow: string;
  textGridTemplateRow: string;
  twoColoredBackground: boolean;
  twoColoredBackgroundOffset: number;
  withBackgroundRectangles: boolean;
}

interface LayoutWithLabel {
  label?: string;
  labelOffset?: OffsetSettings;
  heading?: string;
  header?: React.ReactNode;
  main: React.ReactNode;
  image?: ImageProps;
  withBackgroundRectangles?: boolean;
  rectangles?: RectanglesPositions;
  twoColoredBackground?: boolean;
  layoutSettings?: Partial<LayoutSettings>;
}

interface ContentWithLabelProps {
  label?: string;
  children: React.ReactNode;
  className?: string;
  offset?: OffsetSettings;
  size?: LabelSize;
  twoColoredBackground?: boolean;
  twoColoredBackgroundOffset?: number;
}

export const ContentWithLabel: React.FunctionComponent<
  React.PropsWithChildren<ContentWithLabelProps>
> = ({
  label,
  children,
  offset,
  size = 'normal',
  className,
  twoColoredBackground = false,
  twoColoredBackgroundOffset = 0,
}) => {
  return (
    <div
      className={clsx(
        classes.background,
        twoColoredBackground && classes.background_twoColored,
        className,
      )}
      style={
        {
          '--twoColoredBackgroundOffset': twoColoredBackgroundOffset + 'px',
        } as CSSProperties
      }
    >
      {label && <SectionLabel offset={offset} labelText={label} size={size} />}
      {children}
    </div>
  );
};

const LayoutWithLabel: React.FunctionComponent<
  React.PropsWithChildren<LayoutWithLabel>
> = ({
  label,
  labelOffset,
  heading,
  header = null,
  main,
  image,
  rectangles,
  layoutSettings,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const _layoutSettings: LayoutSettings = {
    ...DEFAULT_LAYOUT_SETTINGS,
    ...layoutSettings,
  };

  useEffect(() => {
    const contentHeightListener = () => {
      if (contentRef.current) {
        const { offsetHeight, offsetTop } = contentRef.current;
        setContentHeight(offsetHeight / 2 + offsetTop);
      }
    };

    contentHeightListener();

    window.addEventListener('resize', contentHeightListener);

    return () => {
      window.removeEventListener('resize', contentHeightListener);
    };
  }, []);

  const layoutCssVars: Record<string, string> = {
    '--textGridTemplateColumn': _layoutSettings.textGridTemplateColumn,
    '--textGridTemplateRow': _layoutSettings.textGridTemplateRow,
  };

  if (image) {
    layoutCssVars['--imageGridTemplateColumn'] =
      _layoutSettings.imageGridTemplateColumn;
    layoutCssVars['--imageGridTemplateRow'] =
      _layoutSettings.imageGridTemplateRow;
  }

  return (
    <ContentWithLabel
      label={label}
      offset={labelOffset}
      twoColoredBackground={_layoutSettings.twoColoredBackground}
      twoColoredBackgroundOffset={contentHeight}
    >
      <div
        className={clsx(
          classes.layout,
          _layoutSettings.twoColoredBackground &&
            classes.layout_twoColoredBackground,
        )}
        style={layoutCssVars}
      >
        <div className={classes.content}>
          <div className={classes.header}>
            {heading && <Heading text={heading} />}
            {header}
          </div>
          <div ref={contentRef}>{main}</div>
        </div>
        {!!image && (
          <div className={classes.imageContainer}>
            <Image
              src={image.src}
              alt={image.alt}
              fill={typeof image.src === 'string'}
              sizes={image.sizes}
              style={image.style}
              quality={image.quality}
            />
          </div>
        )}
      </div>
      {_layoutSettings.withBackgroundRectangles && (
        <BackgroundRectangles rectangles={rectangles} />
      )}
    </ContentWithLabel>
  );
};

export default LayoutWithLabel;
