import clsx from 'clsx';

import { RectanglesPositions } from '../../types';

import classes from './index.module.scss';
import { defaultPositions } from './rectanglesDefaultPositions';

const BackgroundRectangles: React.FunctionComponent<
  React.PropsWithChildren<{
    rectangles?: RectanglesPositions;
  }>
> = ({ rectangles = undefined }) => {
  const rectanglesPositions: RectanglesPositions =
    rectangles ?? defaultPositions;

  return (
    <>
      <div className={clsx(classes.rectanglesWrapper, classes.desktop)}>
        {rectanglesPositions?.desktop.map((rectangle, index) => (
          <div
            className={classes.rectangle}
            style={{
              top: rectangle.y + 'px',
              left: rectangle.x + 'px',
              width: rectangle.width + 'px',
              height: rectangle.height + 'px',
            }}
            key={index}
          />
        ))}
      </div>
      <div className={clsx(classes.rectanglesWrapper, classes.mobile)}>
        {rectanglesPositions?.mobile.map((rectangle, index) => (
          <div
            className={classes.rectangle}
            style={{
              top: rectangle.y + 'px',
              left: rectangle.x + 'px',
              width: rectangle.width + 'px',
              height: rectangle.height + 'px',
            }}
            key={index}
          />
        ))}
      </div>
    </>
  );
};

export default BackgroundRectangles;
