import clsx from 'clsx';

import classes from './index.module.scss';

import { theme } from '@/styles/theme';

type TextColor = 'white' | 'darkBlue';

const Header: React.FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
    color?: TextColor;
    text: string;
  }>
> = ({ className, text, color }) => (
  <header className={clsx(classes.header, className)}>
    <h2
      className={clsx(classes.h2, classes[`h2_${color}`])}
      dangerouslySetInnerHTML={{ __html: text }}
    />
    <svg
      width="45"
      height="5"
      viewBox="0 0 45 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0L40.8333 0L45 5H4.16667L0 0Z"
        fill={theme.colors.lightBlue}
      />
    </svg>
  </header>
);

export default Header;
