export enum BreakpointName {
  Zero = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
  Desktop = 'desktop',
  BigDesktop = 'bigDesktop',
}

export type MaxWidthBreakpointName = Exclude<
  BreakpointName,
  BreakpointName.BigDesktop
>;
