import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import classes from './index.module.scss';

interface Props {
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const SlideButton: FC<Props> = ({
  onClick,
  disabled,
  children,
  className,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        classes.slideButton,
        !disabled && classes.slideButton_enabled,
        className,
      )}
    >
      {children}
    </div>
  );
};

export default SlideButton;
